
header{
  padding: 1rem 0;
  box-shadow: 1px 1px 0px 1px rgba(255,255,255,0.39);
  -webkit-box-shadow: 1px 1px 0px 1px rgba(255,255,255,0.39);
  -moz-box-shadow: 1px 1px 0px 1px rgba(255,255,255,0.39);
  @media screen and (min-width: 768px) {
    padding: 1.5rem 0;
  }
  nav{
    .logo{
      width: 40px;
      @media screen and (min-width: 768px) {
        width: 50px;
      }
    }
    .tagline{
      text-align: center;
      font-family: 'Josefin Sans', sans-serif;
      color: #fff;
      font-size: 16px;
      line-height: 100%;
      @media screen and (min-width: 768px) {
        font-size: 18px;
      }
      @media screen and (min-width: 768px) {
        font-size: 22px;
      }
    }
    .navLink{
      color: #fff;
      font-size: 16px;
      line-height: 100%;
      font-weight: 400;
      background-color: transparent;
      padding: .7rem 1rem;
      border-radius: 20px;
      overflow: hidden;
      background-size: 300% 100%;
      border: 1px solid #fff;
      transition: .2s ease-in-out;
      @media screen and (min-width: 768px) {
        padding: .5rem .8rem;
        font-size: 20px;
        border: 2px solid #fff;
      }
      &:hover{
        background-color: #fff;
        color: #250152;
        transition: .2s ease-in-out;
      }
    }
  }
}
.banner{
  color: #fff;
  padding: 50px 20px;
  max-width: 1480px;
  gap: 30px;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    padding: 50px 20px 150px;
    flex-direction: unset;
  }
  @media screen and (min-width: 1100px){
    padding: 100px 150px 150px;
  }
  &__img{
    width: 100%;
    max-width: 400px;
    @media screen and (min-width: 768px) {
      max-width: 50%;
    }
    img{
      width: 100%;
    }
  }
  &__content{
    .title{
      font-family: 'Josefin Sans', sans-serif;
      font-weight: 600;
      font-size: 28px;
      line-height: 110%;
      color: #fff;
      margin-bottom: 15px;
      @media screen and (min-width: 480px) {
        font-size: 40px;
      }
      @media screen and (min-width: 1100px) {
        font-size: 70px;
        margin-bottom: 30px;
      }
    }
    &-text{
      font-size: 14px;
      color: #fff;
      opacity: .8;
      @media screen and (min-width: 1100px) {
        font-size: 16px;
      }
    }
  }
  &__buttons{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1rem;
    @media screen and (min-width: 480px) {
      flex-direction: row;
    }
    @media screen and (min-width: 768px) {
      flex-direction: column;
    }
    @media screen and (min-width: 1000px) {
      margin-top: 1.5rem;
      flex-direction: row;
    }
  }
}
.info{
  &__container{
    color: #fff;
    flex-wrap: wrap;
    padding: 50px 20px;
    align-items: center;
    max-width: 1480px;
    gap: 2rem;
    flex-direction: column;
    @media screen and (min-width: 480px) {
      gap: 3rem;
    }
    @media screen and (min-width: 768px) {
      padding: 50px 20px 150px;
    }
    @media screen and (min-width: 1000px){
      justify-content: space-between;
      gap: 0;
      flex-direction: row;
    }
    @media screen and (min-width: 1100px){
      padding: 50px 150px 150px;
    }
  }
  &__block{
    width: 100%;
    max-width: 600px;
    border: transparent;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    padding: 16px;
    text-align: center;
    box-shadow: 0px 0px 15px 2px rgba(165,149,255,0.8);
    -webkit-box-shadow: 0px 0px 15px 2px rgba(165,149,255,0.8);
    -moz-box-shadow: 0px 0px 15px 2px rgba(165,149,255,0.8);
    @media screen and (min-width: 480px) {
      gap: 20px;
      font-size: 16px;
      padding: 20px;
    }
    @media screen and (min-width: 1000px){
      width: 30%;
    }
    svg{
      height: 60px;
      @media screen and (min-width: 480px) {
        height: 80px;
      }
    }
  }
}
.loginForm{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  gap: 1.5rem;
  &__inputs{
    position: relative;
    max-width: 400px;
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 480px){
      gap: 2rem;
    }
  }
  &__logo{
    position: absolute;
    top: -100px;
    left: -50px;
    width: 200px;
    z-index: 0;
    opacity: .3;
    @media screen and (min-width: 480px){
      top: -150px;
      left: -150px;
      width: 300px;
    }
  }
  &__title{
    color: #fff;
    font-family: 'Josefin Sans', sans-serif;
    text-transform: capitalize;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    max-width: 400px;
    margin-bottom: -1rem;
    text-align: right;
    width: 100%;
    position: relative;
    z-index: 2;
    @media screen and (min-width: 480px){
      font-size: 33px;
    }
  }
  &__buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    z-index: 2;
    max-width: 400px;
    .reset{
      color: #fff;
      font-size: 12px;
      opacity: .7;
      transition: .2s all;
      @media screen and (min-width: 480px){
        font-size: 14px;
      }
      &:hover{
        opacity: 1;
      }
    }
  }
  &__reset{
    display: flex;
    justify-content: right;
    width: 100%;
    max-width: 400px;
  }
}

.backArrow{
  display: flex;
  color: #fff;
  gap: .5rem;
  position: absolute;
  top: 40px;
  left: 20px;
  font-size: 18px;
  transition: .2s all;
  @media screen and (min-width: 500px){
    gap: 1rem;
    top: 50px;
    left: 50px;
    font-size: 24px;
  }
  svg{
    transform: rotate(180deg);
    width: 16px;
    position: relative;
    transition: .2s all;
    left: 0;
    @media screen and (min-width: 500px){
      width: 20px;
    }
    path{
      fill:#fff
    }
  }
  &:hover{
    svg{
      left: -8px;
      transition: .2s all;
    }
  }
}

.myInput{
  position: relative;
  z-index: 2;
  width: 100%;
  color: #fff;
  &__wrapper{
    position: relative;
    width: 100%;
    height: fit-content;
  }
  &__icon{
    position: absolute;
    z-index: 10;
    bottom: 10px;
    left: 10px;
    width: 20px;
    svg{
      path{
        fill: #13002f; 
      }
    }
  }
  &__label{
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    @media screen and (min-width: 480px){
      font-size: 18px;
    }
  }
  input{
    margin-top: 0px;
    width: 100%;
    height: 40px;
    border-radius: 15px;
    color: #000;
    padding: 5px 15px 5px 45px;
    transition: .2s all;
    &:focus-visible{
      outline: -webkit-focus-ring-color auto 0px;
      box-shadow: 0px 0px 10px 2px rgba(165,149,255,0.8);
      -webkit-box-shadow: 0px 0px 10px 2px rgba(165,149,255,0.8);
      -moz-box-shadow: 0px 0px 10px 2px rgba(165,149,255,0.8);
    }
    &:hover{
      box-shadow: 0px 0px 10px 2px rgba(165,149,255,0.8);
      -webkit-box-shadow: 0px 0px 10px 2px rgba(165,149,255,0.8);
      -moz-box-shadow: 0px 0px 10px 2px rgba(165,149,255,0.8);
    }
  }
}
.downloadBtn{
  width: fit-content;
  display: flex;
  color: #fff;
  font-size: 12px;
  line-height: 100%;
  font-weight: 400;
  background-color: transparent;
  padding: .5rem .8rem;
  border-radius: 15px;
  overflow: hidden;
  background-size: 300% 100%;
  border: 2px solid #fff;
  transition: .2s ease-in-out;
  align-items: center;
  gap: 1rem;
  @media screen and (min-width: 480px) {
    font-size: 14px;
    padding: .7rem 1rem;
  }
  svg{
    height: 30px;
    @media screen and (min-width: 480px) {
      height: 40px;
    }
    path{
      transition: .2s ease-in-out;
      fill: #fff;
    }
  }
  &:hover{
    background-color: #fff;
    color: #250152;
    transition: .2s ease-in-out;
    svg{
      path{
        transition: .2s ease-in-out;
        fill: #250152;
      }
    }
  }
  &-title{
    font-size: 16px;
    text-transform: capitalize;
    @media screen and (min-width: 480px) {
      font-size: 20px;
    }
  }
  &__text{
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
}

.account{
  min-height: 100vh;
  padding: 100px 20px;
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 50px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 200px 20px;
    gap: 20px;
  }
  @media screen and (min-width: 1000px) {
    gap: 50px;
  }
  &__menu{
    flex-direction: column;
    height: fit-content;
    width: 100%;
    border-bottom: 2px solid var(--new-light-grey, #E3E3E3);
    overflow: hidden;
    @media screen and (min-width: 768px){
      border-bottom: none;
      border-right: 2px solid var(--new-light-grey, #E3E3E3);
    }
    @media screen and (min-width: 1000px){
      max-width: 24.375rem;
    }
    &__title{
      padding: 1.25rem 1.88rem;
      color: var(--new-black, #383838);
      font-family: var(--font-family);
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      background: var(--new-almost-white, #FAFAFA);
      text-transform: uppercase;
      @media screen and (min-width: 768px){
        padding: 1.25rem;
      }
      @media screen and (min-width: 1000px){
        font-size: 1.5rem;
        padding: 1.25rem 1.88rem;
      }
    }
    &__nav{
      display: flex;
      flex-direction: column;
      gap: 1.88rem;
      @media screen and (min-width: 500px){
        flex-direction: unset;
        gap: 1.56rem;
      }
      @media screen and (min-width: 768px){
      }
      @media screen and (min-width: 1000px){
        flex-direction: column;
      }
    }
    &__link{
      display: flex;
      align-items: center;
      gap: 0.6rem;
      color: #fff;
      opacity: .5;
      font-family: var(--font-family2);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
      padding: 1rem;
      border: none;
      background: transparent;
      cursor: pointer;
      transition: .2s all;
      svg{
        width: 24px;
        path{
          fill:#fff;
        }
      }
      &:hover{
        opacity: 1;
        transition: .2s all;
      }
      &.active{
        background: #fff;
        opacity: 1;
        color: #13002f;
        svg{
          path{
            fill:#13002f;
          }
        }
      }
      @media screen and (min-width: 400px){
        font-size: 1.25rem;
      }
      @media screen and (min-width: 1000px){
        flex: none;
        gap: 1rem;
      }
    }
    &__row{
      display: flex;
      flex-direction: column;
      gap: 1.88rem;
      flex: 1;
      @media screen and (min-width: 768px){
        gap: 1.56rem;
      }
      @media screen and (min-width: 1000px){
        flex: none;
      }
    }
  }
  &__content{
    width: 100%;
    &__title{
      font-family: 'Josefin Sans', sans-serif;
      font-weight: 600;
      font-size: 32px;
      line-height: 110%;
      color: #fff;
      margin-bottom: 1.5rem;
      @media screen and (min-width: 600px){
        margin-bottom: 2rem;
        font-size: 42px;
      }
    }
    &__inputs{
      max-width: 600px;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-bottom: 2rem;
    }
    &__balance{
      font-weight: 600;
      font-size: 42px;
      line-height: 110%;
      color: #fff;
      margin-bottom: 2rem;
      span{
        font-size: 24px;
      }
      &--title{
        font-size: 18px;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 400;
        line-height: 110%;
        color: #fff;
        margin-bottom: .5rem;
        opacity: .8;
      }
    }
    &__money{
      &--btn{
        display: flex;
        gap: 1rem;
        height: 70px;
        margin-bottom: 2rem;
        align-items: end;
        .myInput{
          max-width: 500px;
        }
        .downloadBtn{
          font-size: 18px;
          height: 40px;
          padding: 0;
          min-width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .deposit{
          color: #56B14A;
          border-color: #56B14A;
            &:hover{
              background-color: #56B14A;
              color: #250152;
            }
        }
        .withdraw{
          color: #bc2b2b;
          border-color: #bc2b2b;
            &:hover{
              background-color: #bc2b2b;
              color: #250152;
            }
        }
      }
    }
  }
  &__transaction{
    &__wrapper{
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 600px;
      gap: 1rem;
      margin-bottom: 2rem;
    }
    &__block{
      border: 1px solid #fff;
      color: #fff;
      border-radius: 20px;
    }
    &__header{
      display: flex;
      padding: 1rem 2rem;
      border-bottom: 1px solid #fff;
      justify-content: space-between;
      font-weight: 400;
      align-items: center;
      font-size: 24px;
      .number{
        font-weight: 400;
        font-size: 24px;
        line-height: 100%;
      }
      .success{
        color: #56B14A;
      }
      .error{
        color: #bc2b2b;
      }
    }
    &__body{
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      &--row{
        width: 100%;
        @media screen and (min-width: 768px){
          width: 50%;
        }
        @media screen and (min-width: 768px){
          width: 100%;
        }
        @media screen and (min-width: 1100px){
          width: 50%;
        }
      }
      @media screen and (min-width: 480px){
        flex-direction: row;
      }
      @media screen and (min-width: 768px){
        flex-direction: column;
      }
      @media screen and (min-width: 1100px){
        flex-direction: row;
      }
      .date{
        font-size: 16px;
        opacity: .7;
        font-weight: 400;
        margin-bottom: 2rem;
      }
      .sender{
        margin-bottom: 1rem;
        span{
          font-size: 14px;
          font-weight: 400;
          opacity: .7;
        }
        .name{
          font-size: 18px;
          opacity: 1;
          margin-left: 10px;
        }
      }
      .amount{
        font-weight: 600;
        font-size: 42px;
        line-height: 110%;
        color: #fff;
        margin-bottom: 2rem;
        text-align: right;
        span{
          font-size: 24px;
        }
        @media screen and (min-width: 480px){
          text-align: left;
        }
        @media screen and (min-width: 768px){
          text-align: right;
        }
        @media screen and (min-width: 1100px){
          text-align: left;
        }
      }
    }
  }
}

footer{
  color: #fff;
  box-shadow: 1px 5px 1px 1px rgba(255,255,255,0.39);
  -webkit-box-shadow: 1px 1px 5px 1px rgba(255,255,255,0.39);
  -moz-box-shadow: 1px 1px 5px 1px rgba(255,255,255,0.39);
}